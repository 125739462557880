<template>
    <div>
      <FormulaBanner :title="title" :formulaCode="formulaCode"/>
      <div class="index">
        <!-- 表单 -->
        <div>
          <div class="top_nav"><span></span>{{title}}
          </div>
          <div class="formBody">
            <!-- 表单 -->
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
            <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
            <!-- <TemplateTreeList :type="'check'" :list-data="listData1" :selectValue="listSelect" @change="listChange1"></TemplateTreeList> -->


            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
            </div>
            <!-- 计算结果 -->
            <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''"> -->
              <!-- <div class="CalculationResults"><span>计算结果</span></div> -->
              <!-- 具体标准 -->
              <!-- <div>
                <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>
              </div> -->
              <!--            <div>-->
              <!--              <div class="CalculationDetail"><span></span>具体标准：</div>-->
              <!--            </div>-->
            <!-- </div> -->
          </div>
        </div>
        <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
        <!-- 后台设置的值 -->
        <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
        <!-- 参考文献 -->
        <FormulaReferences :references-data="tableData.references"></FormulaReferences>
      </div>
  
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            title="应激指数"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
        />
      </van-popup>
  
    </div>
  
  </template>
  
  <script>
  import MyPatient from '@/components/MyPatient.vue'
  import FormulaBanner from '@/components/FormulaBanner.vue'
  import {Toast} from "vant";
  import {getAdmins} from "@/utils/adminDate";
  import {setById} from "@/service/base";
  import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api"; //
  import FormulaExplain from "@/components/template1/FormulaExplain";
  import FormulaReferences from "@/components/template1/FormulaReferences";
  import TemplateFiveList from "@/components/Template5/TemplateFiveList";
  import CalculationResults from "@/components/CalculationResults";
  import router from "@/router";
  // import TemplateTreeList from "@/components/Template3/TemplateTreeList.vue";
  export default {
    name: "medicalSearch",
    data(){
      return{
        compute:true,
        show:false,
        countTextList:[],
        countValue:'',
        listSelect: [],  
        listData: [
          {
            title: 'PaO₂ / FiO₂ (mmHg)',
            columns: ['≥400','300-399','200-299','100-199且需机械通气','<100且需要机械通气'],
            fenzhi: [0,1,2,3,4]  //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '血小板计数(x10^9/L)',
            columns: ['＞149','100-149','50-99','20-49','<20'],
            fenzhi: [0,1,2,3,4]  //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '胆红素(μmol/L)',
            columns: ['＜20','20-32','33-101','102-204','>204'],
            fenzhi: [0,1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '血压及血管加压素',
            columns: ['MAP≥70 mmHg', 'MAP<70 mmHg', '使用血管升压类药物，多巴胺< 5 μg/kg/min 或 多巴酚丁胺(任何剂量)','多巴胺5.0~15 μg/kg/min 或 肾上腺素/去甲肾上腺素< 0.1 g/kg/min','多巴胺>15 μg/kg/min 或 肾上腺素/去甲肾上腺素>0.1 g/kg/min',],
            fenzhi: [0,1,2,3,4] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '格拉斯哥昏迷评分(GCS)',
            columns: ['15','13-14','10-12','6-9','0-5'],
            fenzhi: [0,1,2,3,4]  //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '肌酐/24小时尿量',
            columns: ['< 1.2 mg/dL (< 106 μmol/L)','1.2-1.9 mg/dL(106-168 μmol/L)','2.0-3.4 mg/dL(177-301 μmol/L)','3.5-4.9 mg/dL(309-433 μmol/L)或尿量<500ml','>5.0mg/dL(>442 μmol/L)或尿量<200ml'
            ],
            fenzhi: [0,1,2,3,4]  //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
        ],
        listData1:[
          // {
          //   title: '机械通气',
          //   columns: ['机械通气'],
          // },
        ],
        formulaCode: '',
        shengao: '',
        yingjizhishu: '',
        title: '',
        memberId: '1111',
        selectValue: '',
        username:"",
        value:"",
        showPopover: false,
        showPicker: false,
        columns: ['意识清楚', '轻度意识障碍', '中度意识障碍','昏迷'],
        nianling: '',
        xingbie: null,
        tableData: {},
        countText: '',
        patientId: null,
        result: null,
        yingjizhishuzhi: '',
        tizhong: '',
        xueqingnanongdu: '',
        fenzhiList: [],
      }
    },
    components: {
      // TemplateTreeList,
      FormulaBanner,
      MyPatient,
      FormulaExplain,
      FormulaReferences,
      TemplateFiveList,
      CalculationResults
    },
    async created() {
      await this.test()
      await this.getTableData()
    },
  
    methods:{
      listChange (result, fenzhi) {
      this.show=false
        this.listSelect = result //结果数组
        this.fenzhiList = fenzhi  //分值数组
      },
      listChange1(result){
        console.log(result)
        this.show=false
        if (result.includes('机械通气')) {
          this.fenzhiList.push(1)
        } else {
          const index = this.fenzhiList.indexOf(1);
          if (index !== -1) {
            this.fenzhiList.splice(index, 1);
          }
        }
      },
      coumputTetxt () {
        console.log(this.fenzhiList)
         this.show=true
        let fenshu = 0
        for (let i = 0; i < this.fenzhiList.length; i++) {
          fenshu += this.fenzhiList[i]
        }
        this.result = fenshu
        this.countValue = `${fenshu}分`
      },
      async test(){
        //像接口头部传用户信息
        let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
        this.channel = arrays.os
        this.version = arrays.version
        this.ip = arrays.ip
        await setById(arrays)
      },
      async getTableData () {
        const data = {
          channel: this.$router.currentRoute.query.channel,
          code: router.currentRoute.path.substring(9,router.currentRoute.path.Length),
        }
        const res = await getFormula(data.channel, data.code)
        if (res.code === 0) {
          this.tableData = res.data
          this.formulaCode = res.data.code
          this.title = res.data.chineseName
        }
        if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        
          const data = {
            id: this.$router.currentRoute.query.logId
          }
          const res = await getFormulaHuiXian(data)
          if (res.code === 0) {
            this.listSelect = res.data.content.data
            if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
            this.result = res.data.result.value
            this.countText = res.data.result.result
            this.countValue = `${this.result}分`
            this.fenzhiList = [res.data.result.value]
               this.show=true
            // this.coumputTetxt()
          }
        }
      },
      async submit () {
        if (this.listSelect.length!==this.listData.length) {
        Toast.fail('请完善选择')
        return
      }
        this.coumputTetxt()
        const data = {
          id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
          patientId: this.patientId,
          columnCode: this.tableData.columnCode,
          formulaCode: this.tableData.code,
          channel: this.$router.currentRoute.query.channel,
          location: this.$router.currentRoute.query.location,
          content: {
            data: this.listSelect
            // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
          },
          result: {
            value: this.result,
            DisplayResults:this.countValue
            // result: this.countText
          }
        }
        // console.log("data",data)
        const res = await postFormula(data)
        if (res.code === 0) {
          Toast.success('提交成功')
        } else {
          Toast.fail(res.message)
        }
      },
      calculateAge(birthDateString) {
        const today = new Date();
        const birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        // 如果今天的日期还没到生日月份和日期，则年龄减一
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
      // 开参考文献链接
      openLink(u){
        if (!u) {
          return
        }
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      },
      yingjiClick () {
        this.showPicker = true
      },
      onConfirm(value) {
        this.yingjizhishu = value
        this.showPicker = false
      },
      onCancel() {
        this.showPicker = false
      },
    //   async onSubmit(){
    //     this.coumputTetxt()
    //     const data = {
    //       id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
    //       patientId: this.patientId,
    //       columnCode: this.tableData.columnCode,
    //       formulaCode: this.tableData.code,
    //       channel: this.$router.currentRoute.query.channel,
    //       location: this.$router.currentRoute.query.location,
    //       content: {
    //         shengao: {value: this.shengao, unit: 'cm'},
    //         tizhong: {value: this.tizhong, unit: 'kg'},
    //         nianling: {value: this.nianling, unit: '岁'},
    //         xingbie: {value: this.xingbie == 1 ? '男' : '女'},
    //         // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
    //       },
    //       result: {}
    //     }
    //     if (this.xingbie == 1) {
    //       data.result.nanxing = {value: this.result, unit: 'kcal'}
    //     } else {
    //       data.result.nvxing = {value: this.result, unit: 'kcal'}
    //     }
    //     const res = await postFormula(data)
    //     if (res.code === 0) {
    //       Toast.success('提交成功')
    //     } else {
    //       Toast.fail(res.message)
    //     }
    //   },
      userChange (data) {
        if (data.birthday && data.birthday !== '') {
          this.nianling = this.calculateAge(data.birthday)
        } else {
          this.nianling = ''
        }
        this.xingbie = data.sex
        this.patientId = data.id
        this.selectValue = data
      },
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  .index{
    background: #F6F6F6;
    padding: 10px 15px;
    min-height: 100vh;
    .StandardDescription{
      margin-top:10px;
      .top_nav{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        span {
          display: inline-block;
          width: 5px;
          height: 15px;
          background: #259beb;
          margin-right: 5px;
        }
      }
      .StandardDeatil{
        width:100%;
        padding:15px 10px;
        //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
      }
    }
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .formBody{
      background-color: #fff;
      padding-bottom:10px;
      .CalculationResultsBox{
        padding:0 8px;
        .CalculationResults{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: center;
          margin-bottom:10px
        }
        .CalculationDetail{
          font-size: 15px;
          color: #333333;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom:10px;
          background-color: #f6f6f6;
          // height: 16px;
          span{
            display: inline-block;
            width: 3px;
            height: 24px;
            background: #fe9712;
            margin-right: 5px;
          }
        }
      }
    }
    .titles{
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height: 50px;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
      .titlesName{
        font-size:16px;
        i{
          color: red;
        }
      }
      .information{
        font-size:16px;
        margin-right:4px;
        .PleaseSelect{
          color: #b9b9b9;
        }
        .vanCell{
          width:50px;
          padding:0;
          height: 100%;
        }
      }
      .vBotton{
        width:60px
      }
      .unit{
        height: 50px;
        //   line-height: 50px;
        display: flex;
        align-items: center;
        font-size:16px;
        width:20px;
      }
      .vanIcon{
        font-size:13px;
        color:#a8a8a8;
        font-weight: 650;
      }
    }
  }
  ::v-deep .van-cell {
    padding:0px 0px;
  }
  ::v-deep .van-field__control{
    height: 50px;
    font-size: 16px;
  }
  ::v-deep .van-field__error-message {
    position: absolute;
    top: 30px;
    left: 10px;
  }
  .StandardDeatil2{
    width:100%;
    padding: 15px 10px;
    padding-bottom:1px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    .references{
      margin-bottom:15px
    }
  }
  ::v-deep p {
    padding: 0;
    margin: 0;
  }
  .Abutton {
  
  }
  </style>
  